
import {  Route, Routes } from "react-router-dom";
import Navbar from "./FrontEnd/MainComponets/Header/pages";
import Testimonials from "./FrontEnd/Components/Testrimonials/page";
import Home from "./FrontEnd/MainComponets/Home/home";
import { Footer } from "./FrontEnd/MainComponets/Footer/page";
import Aboutus from "./FrontEnd/Components/AboutUS/Aboutus";
import PrivacyPolicy from "./FrontEnd/Additional/Private_Policy/page";
import TermsAndConditions from "./FrontEnd/Additional/TermsandCondition/page";
import Contactpage from "./FrontEnd/Additional/Contact_Us/contactpage";
import Page from "./FrontEnd/Additional/Underconstruction/page";
import Refund from "./FrontEnd/Additional/Refund_Page/page"


function App() {
  

  return (
    <>
 
    <Navbar/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Private" element={<PrivacyPolicy />} />
          <Route path="/Terms" element={<TermsAndConditions />} />
          <Route path="/Contact" element={<Contactpage />} />
          <Route path="/UnderWork" element={<Page />} />
          <Route path="/Refund" element={<Refund />} />
        </Routes>
      <Footer/>
      
    </>
  );
}

export default App;
