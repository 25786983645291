import { useState } from 'react'; 
import { MdMenu } from 'react-icons/md';
import Logo from '../../../assets/Header_LOGO.png'; 
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const HeaderMenu = [
    {
      id: 1,
      title: "Home",
      link: "/"
    },
    {
      id: 2,
      title: "Course",
      link: "https://lms.forumde.in/courses"
    },
    {
      id: 3,
      title: "Achievers Wall",
      link: "/testimonials"
    },
    {
      id: 4,
      title: "About Us",
      link: "/aboutus"
    },
    {
      id: 5,
      title: "Blog",
      link: "https://lms.forumde.in/blog"
    }
  ];

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <nav className='shadow-sm transition-colors bg-orange-200 duration-300 border-b-2'>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-3">
          <div className='flex-shrink-0'>
            <img className='w-28 sm:w-36 md:w-48 lg:w-56 px-2' src={Logo} alt="logo" />
            <div className="flex space-x-4 px-4   ">
          <a href="https://www.youtube.com/@forum_de" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube text-red-600 text-lg"></i>
          </a>
          <a href="https://www.linkedin.com/company/forumde/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin text-blue-700 text-lg"></i>
          </a>
          <a href="https://www.instagram.com/forum_de_team?igsh=MXBldW5uOGwxbTlkeA==" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram text-pink-500 text-lg"></i>
          </a>
          <a href="https://www.facebook.com/Forum.DE.Team" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook text-blue-600 text-lg"></i>
          </a>
          <a href="https://discord.gg/s2CjQAJVnZ" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-discord text-blue-900 text-lg"></i>
          </a>
          <a href="https://t.me/+yvWJpw3nRrNhMmQ9" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-telegram text-blue-400 text-lg"></i>
          </a>
      </div>
          </div>

          <div className='hidden md:flex flex-grow justify-center'>
            <ul className='flex justify-center items-center gap-2 lg:gap-4 font-bold'>
              {HeaderMenu.map((data) => (
                <li key={data.id} className='px-2 lg:px-3'>
                  <a 
                    className='inline-block py-2 hover:border-b-2 hover:text-orange-500 hover:border-orange-400 transition-colors duration-500 font-semibold text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl' 
                    href={data.link}
                  >
                    {data.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <a href="https://lms.forumde.in/login">
          <div className='hidden md:block'>
            <button className='bg-green-400 border-2 rounded-full font-semibold py-4 px-5 text-2xl border-black hover:bg-green-200'>
              Dashboard
            </button>
          </div>
          </a>

          <div className="md:hidden" onClick={handleToggle}>
            <MdMenu className='text-4xl' />
          </div>
        </div>

        <AnimatePresence> 
          {open && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-16 left-0 w-full bg-white z-20 shadow-lg"
            >
              <div className="py-10 px-6 rounded-3xl">
                <ul className="flex flex-col items-center gap-6 font-semibold text-lg">
                  {HeaderMenu.map((data) => (
                    <li key={data.id}>
                      <Link
                        to={data.link}
                        className='block py-2 px-4 hover:bg-orange-50 hover:text-black rounded'
                      >
                        {data.title}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <button className='bg-orange-400 border-2 rounded-full py-2 px-6 text-lg hover:bg-yellow-300 hover:text-black'>
                      Dashboard
                    </button>
                  </li>
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
