"use client";

import React, { useRef } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Azure from "../../../assets/courses/Azure.jpg";
import Python from "../../../assets/courses/Python.jpg";
import SQL from "../../../assets/courses/SQL.jpg";
import DataScience from "../../../assets/courses/Data Science.jpg";
import 'aos/dist/aos.css';
import AOS from 'aos';
import '@fortawesome/fontawesome-free/css/all.min.css';



AOS.init();

interface Course {
  id: number;
  image: string ;
  title: string;
  description: string;
  duration: string;
  fee: string;
  startDate: string;
  registration: string;
  paymentLink: string;
  explore: string;
}

const courses: Course[] = [
  {
    id: 1,
    image: Azure,
    title: "Azure Data Engineering ",
    description: "Job Assistance || Real-Time Projects || Assignments || Live classes || Recordings",
    duration: "3 Months",
    fee: "Rs 19,999/-",
    startDate: "Starts from 21st September 2024",
    registration: "Registrations Open",
    paymentLink: "https://drive.google.com/file/d/1kaVsfvN7kZkvhnQ3robPvEXCkMw362lj/view?usp=drive_link",
    explore: "https://lms.forumde.in/courses/azure-data-enginnering"
  },
  {
    id: 2,
    image: Python,
    title: "Python Programming ",
    description: "Python from zero to hero course || Hands-On Training || Assignments || Recordings",
    duration: "2 Months",
    fee: "Rs 4,999/-",
    startDate: "New Batch starting soon",
    registration: "Registration Open",
    paymentLink: "https://drive.google.com/file/d/1kcBiR2E7eerR8pe0mgajCoII6hV3zQSV/view?usp=drive_link",
    explore: "https://lms.forumde.in/courses/python-programming-mr-dinesh-kumar"
  },
  {
    id: 3,
    image: SQL,
    title: "Master In SQL",
    description: "Explore the Power of SQL || Ticket to fast-track growth in data science || Recordings",
    duration: "2 Months",
    fee: "Rs 3,999/-",
    startDate: "New Batch starting soon",
    registration: "Registration Open",
    paymentLink: "https://drive.google.com/file/d/1kcr2kjtpiRIZn7kUeLF-Y2VB9-SIMHRo/view?usp=drive_link",
    explore: "https://lms.forumde.in/courses/forumde-sql-batch-1-0-mr-akshay-kaushal"
  },
  {
    id: 4,
    image: DataScience,
    title: "Data Science Program",
    description: "Job Assistance || Ticket to fast-track growth in data science || Recordings",
    duration: "3 Months",
    fee: "Rs 19,999/-",
    startDate: "New Batch starting soon",
    registration: "Registration Open",
    paymentLink: "https://lms.forumde.in/courses",
    explore: "https://lms.forumde.in/courses"
  }
];

// Define a type for the Carousel's ref methods
interface CarouselMethods {
  next: () => void;
  previous: () => void;
}

interface CustomButtonGroupProps {
  next: () => void;
  previous: () => void;
}

const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ next, previous }) => {
  return (
    <div className="flex justify-center items-center py-5 space-x-4" data-aos="fade-up" data-aos-delay="500">
      <button onClick={previous} className="bg-orange-400 hover:bg-orange-200 text-black font-bold py-3 px-3 shadow-md rounded-full">
        <i className="fas fa-chevron-left"></i> Previous
      </button>
      <button onClick={next} className="bg-orange-400 hover:bg-orange-200 text-black font-bold py-3 px-6 shadow-md rounded-full">
        Next <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default function CourseContainer() {
  // Use ref with CarouselMethods type
  const carouselRef = useRef<CarouselMethods>(null);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  return (
    <div className="container mx-auto py-10" data-aos="fade-up" data-aos-delay="500">
      <h1 className={`px-7 mx-auto text-center underline text-7xl font-bold py-8 `} data-aos="fade-up" data-aos-delay="500">Courses</h1>

      <Carousel
        responsive={{
          superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
          desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
          tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
          mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
        }}
        transitionDuration={2000}
        containerClass="carousel-container relative"
        autoPlaySpeed={5000}
        draggable={true}
        centerMode={false}
        autoPlay={true}
        infinite={true}
        swipeable={true}
        // Pass ref directly with methods type
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroup next={handleNext} previous={handlePrev} />}
        itemClass="px-4"
      >
        {courses.map((course) => (
          <div key={course.id} className="bg-white text-black rounded-lg border border-gray-300 shadow-xl px-4 pb-4 pt-2 relative" data-aos="fade-up" data-aos-delay="500">
            <img className="rounded-lg w-full h-50 object-cover mb-2" src={course.image} alt={course.title} />
            <div className="p-4 space-y-3">
              <h6 data-aos="fade-up" data-aos-delay="400" className={`text-2xl font-bold `}>{course.title}</h6>
              <p data-aos="fade-up" data-aos-delay="400" className={`text-lg   `}>{course.description}</p>
              <p data-aos="fade-up" data-aos-delay="400" className={`text-lg   `}><strong>Duration:</strong> {course.duration}</p>
              <p data-aos="fade-up" data-aos-delay="400" className={`text-lg   `}><strong>Fees:</strong> {course.fee}</p>
              <p data-aos="fade-up" data-aos-delay="400" className={`text-lg  font-semibold  `}>{course.startDate}</p>
              <p data-aos="fade-up" data-aos-delay="400" className={`text-lg  font-bold  `}>{course.registration}</p>
              <div data-aos="fade-up" data-aos-delay="400" className="flex  justify-between items-center mt-4">
                <a href={course.explore}>
                  <button data-aos="fade-up" data-aos-delay="400" className="bg-blue-500  hover:bg-blue-300 items-center text-white rounded px-3 py-3 w-fit text-2xl mx-1">Explore</button>
                </a>
                <a href={course.paymentLink}>
                  <button data-aos="fade-up" data-aos-delay="400" className="bg-green-600  hover:bg-green-400 text-white rounded px-2 w-fit py-2 text-lg">Download Syllabus</button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
