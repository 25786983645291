"use client";
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import ProfileImage from "../../../assets/ProfileImage.png"; 


import { WhyUs } from '../WhyUs/WhyUs';
import TeamsData from './Teams (Data and Card)/Teams_Data';
import VisionSection from './VissionSection/Vission';
import MissionSection from './MissionSection/OurMission';



// Font configurations


export default function AboutUs() {
  
  useEffect(() => {
    // Initialize AOS (Animate on Scroll) library
    AOS.init({
      duration: 650,
      once: true,
    });

    // Handle scroll animations
    const handleScroll = () => {
      const animatedText = document.querySelectorAll('.animated-text');
      animatedText.forEach(text => {
        const textTop = text.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (textTop < windowHeight * 0.8) {
          text.classList.add('fade-in-up');
        } else {
          text.classList.remove('fade-in-up');
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
  
      <div data-aos="fade-up" className="container mx-auto px-4 md:px-8 py-6 md:py-10 sm:pt-24 mt-10">
        
        {/* Introduction Section */}
        <section className="bg-orange-300 rounded-xl shadow-xl p-6 md:p-12 sm:pt-40  md:pt-40">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-1">
              <img src={ProfileImage} alt="Profile" className="w-full h-auto rounded-3xl shadow-2xl shadow-orange-400" />
            </div>
            <div className="md:col-span-2 flex flex-col justify-center">
              <p className={`text-4xl md:text-5xl leading-relaxed `}>
                <strong>Dear Students,</strong>
              </p>
              <p className={`mt-4 text-lg`}>
                It is with great pleasure that I welcome you to ForumDE - your gateway to opportunities in Information Technology. As technology evolves rapidly, the demand for skilled IT professionals is higher than ever. We provide industry-level IT training in the latest technologies like Cloud (Azure, AWS, GCP), Data Engineering, Data Science, Docker and Kubernetes, Data Visualization, Business Analysis, and Project Management. At ForumDE, we understand the importance of staying ahead, adapting, and honing skills to meet the industry’s dynamic needs.
              </p>
              <p className={`mt-4 text-lg `}>
                As you begin your journey toward a fulfilling career in IT, I encourage you to leverage the resources and opportunities at ForumDE. Whether you’re a professional aiming to advance your career or a fresh graduate eager to make your mark, our platform offers abundant resources, networking opportunities, and expert guidance to support you every step of the way. On behalf of the entire ForumDE team, I wish you the best in your pursuit of a rewarding career in Information Technology. Together, let’s shape the future of technology and create a brighter tomorrow for generations to come.
              </p>
              <p className={`mt-4 text-lg font-bold `}>
                Best Regards, <br />
                <strong>Arun Kumar</strong><br />
                Founder, ForumDE
              </p>
              
            </div>
          </div>
        </section>

        {/* Why Us Section */}
        <WhyUs />

        {/* Mission Section */}
        <MissionSection />

        {/* Vision Section */}
        <VisionSection />

        {/* Teams Section */}
        <section className="mt-10">
          <h1 className="text-center text-4xl md:text-6xl font-bold underline">Meet Our Team</h1>
          <div className="mt-8 flex flex-col md:flex-row gap-6">
            {TeamsData.map((data) => (
              <div key={data.id} className='flex flex-col border border-gray-200 rounded-lg overflow-hidden shadow-md'>
                <img src={data.img} alt={data.name} className='w-full h-64 object-cover' />
                <div className='p-4'>
                  <h2 className='text-lg font-bold'>{data.name}</h2>
                  <h3 className='text-md font-semibold text-gray-600'>{data.team}</h3>
                  <p className='mt-2 text-gray-700'>{data.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

      </div>
    </>
  );
}
