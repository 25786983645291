import Details from '../../Components/Details/Page'
import ContactUsButtons from '../../Components/ContactUs/ContactPage'
import Upcoming from '../../Components/UpcomingCourse/Pages'
import CourseContainer from '../../Components/Course/Course'
import MyCertificate from '../../Components/Certificate/Certificate'
import Mentors from '../../Components/Mentor/page'
import Logo_Marquee from '../../Additional/LogoMarquee/LogoMarquee'
import Video from '../../Components/Video/Video'
import FAQ from '../../Components/Faq/FaqPage'
import CustomMarquee from '../../Components/Marquee/Marquee'
import Company_Marquee from '../../Additional/LogoMarquee/infinite_scrolling'



const Home = () => {
  return (
   
        <>
        <div className='space-y-8 gap-4'>
          
        <Details />
          <CustomMarquee/>
          <ContactUsButtons />
          <Upcoming />
          <CourseContainer />
          <MyCertificate />
          <Mentors />
         
          <Logo_Marquee />
          <Company_Marquee/>
          <Video />
          <FAQ />
        </div>
        </>
     

  
  )
}

export default Home