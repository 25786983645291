import Marquee from 'react-fast-marquee';


// Import your logos
import AzureLab from '../../../assets/Logo/Azure lab.jpg';
import Azure from '../../../assets/Logo/Azure.jpg';
import Python from '../../../assets/Logo/Python.jpg';
import AzureScience from '../../../assets/Logo/AzureScience.jpg';
import DataScience from '../../../assets/Logo/DataScience.jpg';
import Databrick from '../../../assets/Logo/Databriks.jpg';
import MySql from '../../../assets/Logo/MySql.jpg';
import PySpark from '../../../assets/Logo/PySpark.jpg';
import Spark from '../../../assets/Logo/Spark.jpg';
// Define a type for the logo data
interface Logo {
  src: string;
  alt: string;
}

// Create an array of logos
const logos: Logo[] = [
  { src: AzureLab, alt: 'Azure Lab' },
  { src: AzureScience, alt: 'Azure Science' },
  { src: Databrick, alt: 'Databrick' },
  { src: DataScience, alt: 'Data Science' },
  { src: Azure, alt: 'Azure' },
  { src: MySql, alt: 'MySQL' },
  { src: Spark, alt: 'Spark' },
  { src: PySpark, alt: 'PySpark' },
  { src: Python, alt: 'Python' },
];

const Logo_Marquee = () => {
  return (
    <div className="text-center py-6">
      <h1 className="font-extrabold underline p-3 text-4xl md:text-5xl lg:text-6xl" data-aos="fade-up" data-aos-delay="1200">
        Our Technology
      </h1>
      <div data-aos="fade-up" data-aos-delay="1000" className="py-4 rounded-xl w-full h-full">
        <Marquee speed={100}>
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center mx-4">
              <img src={logo.src} alt={logo.alt} className="h-32 w-auto" />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Logo_Marquee;
