import React from 'react';

import testimonialData from '../StudentData/Data';




const TestimonialsCards: React.FC = () => {
  return (
    <div className="py-3 px-4 sm:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonialData.map((data) => (
          <div key={data.name} className="bg-gray-800 shadow-lg rounded-lg p-4 flex flex-col items-center">
            <img
              src={data.image}
              alt={data.name}
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-full border-4 border-white/70"
              width={128}
              height={128}
            />
            <h1 className={`text-xl sm:text-2xl font-bold text-white text-center mt-4  `}>{data.name}</h1>
            <div className="flex items-center space-x-2 mt-2">
              <h2 className={`text-lg sm:text-xl text-white font-semibold  `}>{data.companyname}</h2>
              <img
                src={data.companylogo}
                alt={`${data.companyname} logo`}
                className="w-10 h-10 sm:w-12 sm:h-12 rounded-full"
                width={48}
                height={48}
              />
            </div>
            <div className="text-yellow-400 text-xl mt-2">★ ★ ★ ★ ★</div>
            <p
              className={`text-gray-300 text-base sm:text-lg mt-2  `}
              style={{ lineHeight: '1.6' }}
            >
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsCards;
